<template>
  <div
    class="page-custom container-fluid pt-4 pb-5"
    :class="{ 'page-independent': !viewOnly }"
  >
    <div class="container mt-2">
      <div class="row">
        <div class="col-xl-1"></div>
        <div v-if="loaded" class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t.translate("FRM_ASSESSMENT_SERVICE_INFO") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <div class="pl-lg-4 pr-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_NAME_A_EN")
                    }}</label>
                    <el-input
                      v-model="certItem.ServiceName"
                      class="mb-2"
                      :disabled="viewOnly"
                      :class="{ 'inp-invalid': $h.isBlank(certItem.ServiceName) }"
                    />
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_NAME_A_CHT")
                    }}</label>
                    <el-input
                      v-model="certItem.ServiceNameCHT"
                      class="mb-2"
                      :disabled="viewOnly"
                      :class="{ 'inp-invalid': $h.isBlank(certItem.ServiceNameCHT) }"
                    />
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_NAME_A_CHS")
                    }}</label>
                    <el-input
                      v-model="certItem.ServiceNameCHS"
                      class="mb-2"
                      :disabled="viewOnly"
                      :class="{ 'inp-invalid': $h.isBlank(certItem.ServiceNameCHS) }"
                    />
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_NAME_B_EN")
                    }}</label>
                    <el-input
                      v-model="certItem.ServiceNameExtra"
                      class="mb-2"
                      :disabled="viewOnly"
                    />
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_NAME_B_CHT")
                    }}</label>
                    <el-input
                      v-model="certItem.ServiceNameExtraCHT"
                      class="mb-2"
                      :disabled="viewOnly"
                    />
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_NAME_B_CHS")
                    }}</label>
                    <el-input
                      v-model="certItem.ServiceNameExtraCHS"
                      class="mb-2"
                      :disabled="viewOnly"
                    />
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_DESCRIPTION_EN")
                    }}</label>
                    <!-- <el-input
                      v-model="certItem.Description"
                      :rows="4" maxlength="100"
                      type="textarea"
                      :disabled="viewOnly"
                      style="margin-bottom: 10px"
                    /> -->

                    <div class="mb-3">
                      <TinyEditor :disabled="viewOnly" v-model="certItem.Description" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_DESCRIPTION_CHT")
                    }}</label>
                    <!-- <el-input
                      v-model="certItem.DescriptionCHT"
                      :rows="4" maxlength="100"
                      type="textarea"
                      :disabled="viewOnly"
                      style="margin-bottom: 10px"
                    /> -->

                    <div class="mb-3">
                      <TinyEditor
                        :disabled="viewOnly"
                        v-model="certItem.DescriptionCHT"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_DESCRIPTION_CHS")
                    }}</label>
                    <!-- <el-input
                      v-model="certItem.DescriptionCHS"
                      :rows="4" maxlength="100"
                      type="textarea"
                      :disabled="viewOnly"
                      style="margin-bottom: 10px"
                    /> -->

                    <div class="mb-3">
                      <TinyEditor
                        :disabled="viewOnly"
                        v-model="certItem.DescriptionCHS"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_DISPLAY_DESC_IN_FRONT")
                    }}</label>
                    <div class="mb-3">
                      <el-radio-group
                        :disabled="viewOnly"
                        v-model="certItem.DisplayDescInFrontend"
                      >
                        <el-radio :label="1" class="text-uppercase">{{
                          $t.translate("LBL_ON")
                        }}</el-radio>
                        <el-radio :label="0" class="text-uppercase">{{
                          $t.translate("LBL_OFF")
                        }}</el-radio>
                      </el-radio-group>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_HIGHLIGHT")
                    }}</label>
                    <div class="mb-3">
                      <el-input
                        v-model="certItem.Highlight"
                        :rows="3"
                        type="textarea"
                        :placeholder="$t.translate('PLH_ADD_CONTENT_HERE')"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_HIGHLIGHT_CHT")
                    }}</label>
                    <div class="mb-3">
                      <el-input
                        v-model="certItem.HighlightCHT"
                        :rows="3"
                        type="textarea"
                        :placeholder="$t.translate('PLH_ADD_CONTENT_HERE')"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_HIGHLIGHT_CHS")
                    }}</label>
                    <div class="mb-3">
                      <el-input
                        v-model="certItem.HighlightCHS"
                        :rows="3"
                        type="textarea"
                        :placeholder="$t.translate('PLH_ADD_CONTENT_HERE')"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_TYPE_OF_DELIVERABLE")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.TypeCode"
                        @change="changeType"
                        class="form-group w-100"
                        :disabled="viewOnly || certItem.ServiceKey != null"
                      >
                        <el-option
                          v-for="item in ServiceTypeOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_TYPE_OF_CERTIFICATE")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.GenerationType"
                        @change="certItem.Template = null"
                        class="form-group w-100"
                        :disabled="viewOnly"
                      >
                        <el-option
                          v-for="item in TypeOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("LBL_CERT_NO_PREFIX")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.CertificatePrefix"
                        class="form-group w-100"
                        :class="{ 'inp-invalid': $h.isBlank(certItem.CertificatePrefix) }"
                        :disabled="viewOnly || certItem.TypeCode != 'CERTIFICATE'"
                      >
                        <el-option
                          v-for="item in CertPrefixOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("LBL_CERT_NO_AND_DATE_ASSIGNMENT")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.CertNoDate"
                        class="form-group w-100"
                        :class="{ 'inp-invalid': $h.isBlank(certItem.CertNoDate) }"
                        :disabled="viewOnly || certItem.TypeCode != 'CERTIFICATE'"
                      >
                        <el-option
                          v-for="item in CertificateDateOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("LBL_NO_OF_ASSESSMENT")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.AssessmentNo"
                        class="form-group w-100"
                        :disabled="viewOnly"
                      >
                        <el-option
                          v-for="item in AssessmentNoOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("LBL_DISCLOSURE_DOCUMENT_SETTING")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.DisclosureType"
                        class="form-group w-100"
                        :disabled="viewOnly"
                      >
                        <el-option
                          v-for="item in TypeOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_TYPE_OF_SERVICE")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.ServiceCatgCode"
                        class="form-group w-100"
                        :disabled="viewOnly"
                        :class="{ 'inp-invalid': $h.isBlank(certItem.ServiceCatgCode) }"
                      >
                        <el-option
                          v-for="item in ServiceCatgOpts"
                          :key="item.value"
                          :label="$t.translate(item.label)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_TAGS_OF_SERVICE", "Tags of Service")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.Tags"
                        multiple
                        :multiple-limit="3"
                        class="form-group w-100"
                        :disabled="viewOnly"
                        :class="{ 'inp-invalid': $h.isBlank(certItem.Tags) }"
                      >
                        <el-option
                          v-for="item in TagOpts"
                          :key="item.TagCode"
                          :label="$h.formatName(item, 'Name')"
                          :value="item.TagCode"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_VALIDITY_PERIOD")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.ValidityPeriod"
                        class="form-group w-100"
                        :disabled="viewOnly"
                      >
                        <el-option
                          v-for="item in ValidPeriodOpts"
                          :key="item.value"
                          :label="$t.translate(`LBL_EXP_${item.value}`)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_ASSESSMENT_CHECKLIST_MACHFORM")
                    }}</label>
                    <div class="block">
                      <div class="form-group d-flex">
                        <el-select
                          :placeholder="$t.translate('FRM_SELECT')"
                          v-model="certItem.AssessmentChecklist"
                          class="w-100"
                          :disabled="viewOnly || certItem.ServiceKey != null"
                          :class="{
                            'inp-invalid': $h.isBlank(certItem.AssessmentChecklist),
                          }"
                        >
                          <el-option
                            v-for="item in ChecklistOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>

                        <el-button
                          :disabled="viewOnly || certItem.ServiceKey != null"
                          @click="getMachform(true)"
                          ><i class="c-pointer fas fa-sync"></i
                        ></el-button>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_ASSESSMENT_SERVICE_TEMPLATE")
                    }}</label>
                    <el-select
                      :placeholder="$t.translate('FRM_NO_SELECTED_TEMPLATE')"
                      v-model="certItem.Template"
                      class="form-group w-100"
                      :disabled="viewOnly || certItem.GenerationType == 'MANUAL'"
                      :class="{
                        'inp-invalid': $h.isBlank(certItem.Template),
                      }"
                    >
                      <el-option
                        v-for="item in TemplateOpts"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>

                  <div class="col-lg-6">
                    <p class="form-control-label mb-2">
                      {{ $t.translate("FRM_ELIGIBLE_ASSESSOR_ACCOUNT") }}
                    </p>
                    <div class="d-flex">
                      <el-select
                        v-model="certItem.AssessorList"
                        multiple
                        :disabled="viewOnly"
                        :placeholder="$t.translate('TYPE_HERE_TO_SEARCH')"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="(r) => searchStaff(r, true)"
                        class="form-group w-100"
                        :class="{ 'inp-invalid': $h.isBlank(certItem.AssessorList) }"
                      >
                        <el-option
                          v-for="item in assessorList"
                          :key="item.UserKey"
                          :label="$h.getFullName(item)"
                          :value="item.UserKey"
                        />
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <p class="form-control-label mb-2">
                      {{ $t.translate("FRM_APPLICABLE_CURRENCY") }}
                    </p>
                    <div class="mb-4 d-flex">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        v-model="certItem.Currency"
                        @change="changeCurrency"
                        multiple
                        class="currency-row"
                        :disabled="viewOnly"
                        :class="{
                          'inp-invalid': $h.isBlank(certItem.Currency),
                        }"
                      >
                        <el-option
                          v-for="item in CurrencyOpts"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-12" v-if="certItem.Currency.length">
                    <p class="form-control-label mb-2">
                      {{ $t.translate("FRM_FEES") }}
                    </p>
                    <div class="mb-4 d-flex">
                      <el-table class="bg-trans" :data="certItem.AmountList">
                        <el-table-column :label="$t.translate('FRM_CURRENCY')">
                          <template #default="scope">
                            {{ $h.mapLabel(scope.row.CurrencyCode, CurrencyOpts) }}
                          </template>
                        </el-table-column>

                        <el-table-column :label="$t.translate('FRM_APPLICATION_FEE')">
                          <template #default="scope">
                            <el-input-number
                              v-model="scope.row.ApplicationFee"
                              :disabled="viewOnly"
                              controls-position="right"
                              :min="0"
                            ></el-input-number>
                          </template>
                        </el-table-column>

                        <el-table-column :label="$t.translate('FRM_ASSESSMENT_FEE')">
                          <template #default="scope">
                            <el-input-number
                              v-model="scope.row.AssessmentFee"
                              :disabled="viewOnly"
                              controls-position="right"
                              :min="0"
                              :class="{
                                'inp-invalid':
                                  $h.isBlank(scope.row.AssessmentFee) ||
                                  !scope.row.AssessmentFee,
                              }"
                            ></el-input-number>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>

                  <template v-for="(it, index) in LogoList" :key="index">
                    <div class="col-lg-6 mb-4 mb-md-3">
                      <label class="form-control-label"
                        >{{ $t.translate("FRM_SCHEME_LOGO") }}
                        {{ $t.translate(`LANG_${it.lang}`) }}</label
                      >

                      <div>
                        <template v-if="certItem?.[it.field]">
                          <div style="padding-right: 20px">
                            <el-image
                              v-if="certItem[it.field]"
                              style="width: 100px; height: 100px"
                              fit="contain"
                              :preview-teleported="true"
                              :preview-src-list="[
                                $axios.formulateUrl(
                                  `/image/view/tmp/${certItem[it.field]}`
                                ),
                              ]"
                              :src="
                                $axios.formulateUrl(
                                  `/image/view/tmp/${certItem[it.field]}`
                                )
                              "
                            ></el-image>
                            <span class="uploaded-file">{{ certItem[it.field] }}</span>
                          </div>
                        </template>
                        <div>
                          <el-upload
                            :disabled="viewOnly"
                            :show-file-list="false"
                            :action="$axios.formulateUrl('/media/upload/tmp')"
                            :file-list="[]"
                            :headers="$axios.getHeaders()"
                            :on-error="$axios.uploadError"
                            :on-progress="$axios.uploadProgress"
                            :on-success="
                              (response, file) =>
                                sucUploadDocu(response, file, it.field, $axios.doneUpload)
                            "
                            :accept="$h.getImageFileTypes()"
                          >
                            <div class="simple-upload-button">
                              <span class="text-helper">{{
                                $t.translate("LBL_UPLOAD_HELPER")
                              }}</span>

                              <el-button :disabled="viewOnly">{{
                                $t.translate("BTN_UPLOAD_IMAGE")
                              }}</el-button>
                            </div>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="col-lg-6 mb-4 mb-md-3">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SERVICE_PICTURE")
                    }}</label>

                    <div>
                      <template v-if="certItem?.ServicePicture">
                        <div style="padding-right: 20px">
                          <el-image
                            v-if="certItem.ServicePicture"
                            style="width: 120px; height: 80px"
                            fit="contain"
                            :preview-teleported="true"
                            :preview-src-list="[
                              $axios.formulateUrl(
                                `/image/view/tmp/${certItem.ServicePicture}`
                              ),
                            ]"
                            :src="
                              $axios.formulateUrl(
                                `/image/view/tmp/${certItem.ServicePicture}`
                              )
                            "
                          ></el-image>
                          <span class="uploaded-file">{{ certItem.ServicePicture }}</span>
                        </div>
                      </template>
                      <div>
                        <el-upload
                          :disabled="viewOnly"
                          :show-file-list="false"
                          :action="$axios.formulateUrl('/media/upload/tmp')"
                          :file-list="[]"
                          :headers="$axios.getHeaders()"
                          :on-error="$axios.uploadError"
                          :on-progress="$axios.uploadProgress"
                          :on-success="
                            (response, file) =>
                              sucUploadDocu(
                                response,
                                file,
                                'ServicePicture',
                                $axios.doneUpload
                              )
                          "
                          :accept="$h.getImageFileTypes()"
                        >
                          <div class="simple-upload-button">
                            <span class="text-helper">{{
                              $t.translate("LBL_UPLOAD_HELPER")
                            }}</span>

                            <el-button :disabled="viewOnly">{{
                              $t.translate("BTN_UPLOAD_IMAGE")
                            }}</el-button>
                          </div>
                        </el-upload>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-4 mb-md-0">
                    <label class="form-control-label">{{
                      $t.translate("FRM_STATUS")
                    }}</label>
                    <div>
                      <el-radio-group :disabled="viewOnly" v-model="certItem.IsActive">
                        <el-radio :label="1">{{ $t.translate("LBL_ACTIVE") }}</el-radio>
                        <el-radio :label="0">{{ $t.translate("LBL_INACTIVE") }}</el-radio>
                      </el-radio-group>
                    </div>
                  </div>

                  <div class="col-lg-6 mb-4 mb-md-0">
                    <label class="form-control-label">{{
                      $t.translate("FRM_SKIP_ASSESSMENT_CYCLE")
                    }}</label>
                    <div>
                      <el-switch
                        v-model="certItem.IsSkipCycle"
                        :active-value="1"
                        :inactive-value="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </card>

          <card shadow type="secondary mt-4" v-if="certItem.AssessmentChecklist">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{
                        $t.translate("TTL_DEFINE_DISCLOSURE_ITEM_OF_ASSESSMENT_CHECKLIST")
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <AssessmentChecklistQuestion
              @change="triggerList"
              :triggers="true"
              :initial="certItem.DisclosureList"
              :data="{
                AssessmentChecklist: certItem.AssessmentChecklist,
              }"
            />
          </card>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!viewOnly" class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button type="default" class="mr-4 d-none d-md-block" @click="$r.goBack">
        {{ $t.translate("BTN_CANCEL") }}
      </el-button>
      <el-button type="primary" class="" @click="saveChanges">
        {{ $t.translate("BTN_CONFIRM") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import AssessmentChecklistQuestion from "@/components/AssessmentChecklistQuestion";
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, reactive, ref, computed } from "vue";
import { helpers } from "@/utils/helpers";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import { store } from "@/store";
import TinyEditor from "@/components/TinyEditor";

export default {
  components: {
    TinyEditor,
    AssessmentChecklistQuestion,
  },
  setup() {
    const loaded = ref(false);
    const route = useRoute();

    let viewOnly = ref(route?.path?.includes("/view") || false);
    let TagOpts = ref([]);
    let {
      CertificateDateOpts,
      CertPrefixOpts,
      AssessmentNoOpts,
      TypeOpts,
      ServiceTypeOpts,
      ServiceCatgOpts,
      AccessmentTypeOpts,
      ValidPeriodOpts,
      CurrencyOpts,
    } = store.getters["config/getData"]?.envConf;

    let LogoList = [
      {
        field: "Logo",
        lang: "EN",
      },
      {
        field: "LogoCHT",
        lang: "CHT",
      },
      {
        field: "LogoCHS",
        lang: "CHS",
      },
    ];

    let certItem = reactive({
      AssessmentNoOpts,
      ServiceKey: route?.params?.key || null,
      ServiceName: "",
      ServiceNameCHS: "",
      ServiceNameCHT: "",
      ServiceNameExtra: "",
      ServiceNameExtraCHS: "",
      ServiceNameExtraCHT: "",
      Highlight: "",
      HighlightCHS: "",
      HighlightCHT: "",
      Description: "",
      DescriptionCHS: "",
      DescriptionCHT: "",
      DisplayDescInFrontend: 1,
      IsSkipCycle: 0,
      AccessmentTypeList: [],
      AssessorList: [],
      CertNoDate: CertificateDateOpts[0].value,
      AssessmentNo: AssessmentNoOpts[0].value,
      CertificatePrefix: CertPrefixOpts[0].value,
      GenerationType: TypeOpts[0].value,
      DisclosureType: TypeOpts[0].value,
      TypeCode: ServiceTypeOpts[0].value,
      ServiceCatgCode: ServiceCatgOpts[0].value,
      Tags: [],
      ValidityPeriod: "NOEXPIRY",
      AssessmentChecklist: "", // select from machform forms list
      Template: null, // template document
      Logo: null,
      LogoCHS: null,
      LogoCHT: null,
      ServicePicture: null, // should be 3:2 ratio
      AmountList: [],
      Currency: [],
      IsActive: 1,
    });

    let ChecklistOpts = ref([]);
    let TemplateOpts = ref([]);

    let triggerList = (val) => {
      certItem.DisclosureList = _.filter(val, (r) => r.Disclosure);
    };

    let getMachform = async (isSync = false) => {
      if (isSync) {
        certItem.AssessmentChecklist = "";
        await post("/assessment/sync/machform");
      }

      let res = await get("/assessment/checklist");
      ChecklistOpts.value = _.map(res?.CheckList, (r) => {
        return {
          value: r.MachformKey,
          label: r.Name,
        };
      });
    };

    onMounted(async () => {
      await getMachform();
      await searchStaff(null, true);

      let res = await get("/assessment/tag/list", { page: 1, size: 9999 });
      TagOpts.value = res?.data || [];

      let resp = await get("/assessment/template", {});
      TemplateOpts.value = _.map(resp?.TemplateList, (r) => {
        return {
          value: r.AssessmentTemplateKey,
          label: r.Name,
        };
      });

      if (route?.params?.key) {
        let res = await get("/assessment/view", {
          AssessmentServiceKey: route.params.key,
        });
        let keys = Object.keys(certItem);
        for (let key of keys) {
          certItem[key] =
            res?.AssessmentService?.[key] ||
            (typeof res?.AssessmentService?.[key] == "boolean" ||
            typeof res?.AssessmentService?.[key] == "number"
              ? res?.AssessmentService?.[key]
              : "");
        }

        // assessorList.value = [...certItem.AssessorList];
        certItem.AssessorList = _.map(certItem.AssessorList, (r) => r.UserKey);
        certItem.DisclosureList = res?.AssessmentService?.DisclosureList || [];
      }

      loaded.value = true;
    });

    const uploadUrl = async (apiLink) => {
      return formulateUrl(apiLink);
    };

    const sucUploadDocu = async (response, raw, field, cb) => {
      let { File } = response?.data || {};
      certItem[field] = File;
      cb();
    };

    const saveChanges = async () => {
      if (helpers.isSubmitting()) return;

      let res = await post("/assessment/save", certItem, true);
      if (!isValidResponse(res)) return;

      let Code = certItem.ServiceKey ? "MSG_CERT_UPDATED" : "MSG_CERT_ADDED";
      helpers.showMessage({
        Code,
        Message: Code,
      });
      router.goBack();
    };

    const changeCurrency = () => {
      _.each(certItem.Currency, (code) => {
        let found = _.find(certItem.AmountList, (r) => {
          return r.CurrencyCode == code;
        });
        if (!found) {
          certItem.AmountList.push({
            CurrencyCode: code,
            ApplicationFee: 0,
            AssessmentFee: 0,
          });
        }
      });
      //remove??
      certItem.AmountList = _.filter(certItem.AmountList, (r) => {
        return certItem.Currency.indexOf(r.CurrencyCode) > -1;
      });
    };

    let assessorList = ref([]);

    const searchStaff = async (query, isAssessor) => {
      let result = [];
      let res = await get("/user/list", {
        page: 1,
        size: 999,
        RoleCode: "ASSESSOR",
        Keyword: query || "",
      });
      result = res?.data || [];
      if (isAssessor) {
        assessorList.value = result;
      }
    };

    const changeType = () => {
      if (certItem.TypeCode == "CERTIFICATE") {
        certItem.CertNoDate = CertificateDateOpts[0].value;
        certItem.CertificatePrefix = CertPrefixOpts[0].value;
      } else {
        certItem.CertificatePrefix = null;
        certItem.CertNoDate = null;
      }
    };

    return {
      changeType,
      CertificateDateOpts,
      AssessmentNoOpts,
      CertPrefixOpts,
      TypeOpts,
      triggerList,
      LogoList,
      assessorList,
      searchStaff,

      getMachform,
      changeCurrency,
      loaded,
      viewOnly,
      certItem,
      ServiceTypeOpts,
      ServiceCatgOpts,
      TagOpts,
      AccessmentTypeOpts,
      ValidPeriodOpts,
      ChecklistOpts,
      TemplateOpts,
      CurrencyOpts,
      uploadUrl,
      sucUploadDocu,
      saveChanges,
    };
  },
};
</script>
