<template>
  <div class="checklist-body">
    <div class="trigger-container" v-if="ShowTriggers">
      <el-button size="small" type="primary" @click="select(true)">{{
        $t.translate("BTN_SELECT_ALL")
      }}</el-button>

      <el-button size="small" type="warning" @click="select(false)">{{
        $t.translate("BTN_DESELECT_ALL")
      }}</el-button>
    </div>

    <el-table :data="Elements">
      <el-table-column>
        <template #header>
          <b class="table-header-custom-b">{{ CheckListName }}</b>
        </template>

        <template #default="scope">
          <div class="machform-checklist">
            <label for="" class="break-space-word">{{ scope.row.element_title }}</label>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="100" :label="$t.translate('LBL_DISCLOSURE')">
        <template #default="scope">
          <el-switch
            @change="triggerList"
            v-model="scope.row.Disclosure"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { get } from "@/utils/axios";

export default {
  props: {
    triggers: {
      type: Boolean,
      default: false,
    },
    initial: Object,
    data: Object,
    reject: Boolean,
  },
  emits: ["change"],
  setup(props, context) {
    let Elements = ref([]);
    let Form = ref({});
    let ShowTriggers = ref(props?.triggers ? true : false);

    let triggerList = () => {
      context.emit(
        "change",
        _.map(Elements.value, (r) => {
          return { Disclosure: r.Disclosure, ElementId: parseInt(r.element_id) };
        })
      );
    };

    let CheckListName = computed(() => {
      return `${Form?.value?.form_name || ""} ${Form?.value?.form_description || ""}`;
    });

    let select = (IsSelect) => {
      Elements.value = _.map(Elements.value, (r) => {
        return { ...r, Disclosure: IsSelect ? 1 : 0 };
      });
      triggerList();
    };

    onMounted(async () => {
      let res = await get("/assessment/checklist/question", {
        AssessmentChecklist: props.data.AssessmentChecklist,
      });

      Form.value = res?.Form;

      Elements.value = _.map(res?.Elements, (r) => {
        let ElementId = parseInt(r.element_id);
        let found = _.find(props?.initial || [], (a) => a.ElementId == ElementId);

        return { ...r, Disclosure: found ? 1 : 0, Host: res?.Host };
      });
    });

    return { Elements, select, ShowTriggers, CheckListName, triggerList };
  },
};
</script>
<style>
.checklist-body .el-table tr,
.checklist-body .el-table th,
.checklist-body .el-table,
.checklist-body .el-table__body-wrapper {
  background: none;
}
.machform-checklist * {
  color: black;
  font-size: 0.85rem;
}

.machform-checklist label {
  margin: 0px;
}
.machform-checklist {
  /* margin-bottom: 10px; */
}
.table-header-custom-b {
  color: #000;
}

.machform-checklist a {
  color: #f1832d;
}
.trigger-container {
  margin-bottom: 10px;
}
</style>
