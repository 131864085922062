<template>
  <editor
    :disabled="disabled"
    api-key="0iluqaovi0usuywqw3nqj9smi3n3ipfeb7llhs372dmyscnh"
    :init="{
      ...$configs.tinymce,
      images_upload_handler,
    }"
    v-model="formData.Value"
    @change="changeInput"
  />
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import { onMounted, reactive, ref, computed, watch } from "vue";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { configs, extendedConfigs } from "@/utils/configs";

export default {
  props: {
    modelValue: String,
    disabled: Boolean,
  },
  emits: ["change", "update:modelValue"],
  components: { editor: Editor },
  setup(props, context) {
    let formData = reactive({ Value: props.modelValue });

    watch(props, () => {
      if (props.modelValue) {
        formData.Value = props.modelValue;
      }
    });

    let changeInput = () => {
      context.emit("update:modelValue", formData.Value);
      context.emit("change");
    };

    let images_upload_handler = async (blobInfo) => {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      let res = await post("/media/upload/tinymce", formData);
      return configs.environment == "local"
        ? formulateUrl(`/image/view/tinymce/${res.File}`)
        : `/api/image/view/tinymce/${res.File}`;
    };

    return { formData, changeInput, images_upload_handler };
  },
};
</script>
